import cn from 'classnames';

import {autoScrollFeatureTableParam} from '@/pages/shopify.com/($locale)/pricing/components/Comparison/hooks/useScrollToTableRow';
import {useTranslations} from '@/hooks/useTranslations';
import {camelize} from '@/utils/utils';

import {isPlusPlan} from '../utils';

import {type normalizeServerTableData} from './utils/normalizeServerTableData';
import {EmptyRowValue} from './EmptyRowValue';
import {RowSectionDivider} from './RowSectionDivider';
import {getCellClasses} from './utils/getCellClasses';
import {FeatureDetails} from './FeatureDetails';
import {CellValue} from './CellValue';

export default function FeaturesTableV2({
  table,
  mobile = false,
  mobileTabActivePlan = 'basic',
  isSignupLink = true,
}: {
  table: ReturnType<typeof normalizeServerTableData>;
  mobile?: boolean;
  mobileTabActivePlan?: string;
  isSignupLink?: boolean;
}) {
  const {tableData, planMap} = table;
  const {t} = useTranslations();

  const {
    autoScrollHighlightClass,
    borderClasses,
    leftCellWidth,
    otherCellsWidth,
  } = getCellClasses({mobile});

  const planCount = Object.keys(planMap).length;

  let currentSectionTitle = '';
  let currentFeatureCategory = '';
  const columnsIncludingEmptyRows = [null, ...Object.values(planMap)];

  const rows = tableData.map((row) => {
    if ('title' in row) {
      currentSectionTitle = row.title;
      currentFeatureCategory = row.featureCategory;
      return (
        <RowSectionDivider
          id={`row-section-heading-${row.featureCategory}`}
          key={`row-section-heading-${row.featureCategory}`}
          row={row}
          colspan={planCount + 1}
          mobile={mobile}
        />
      );
    }

    // rows with cells
    return (
      <tr
        key={`${row.type}`}
        className={cn({
          'hover:bg-shade-10': row.type !== 'bottomLinks',
          [autoScrollHighlightClass]:
            row.type === autoScrollFeatureTableParam(),
        })}
        data-pricing-row-type={row.type}
      >
        {/* first column is the summary and details */}
        <FeatureDetails
          row={row}
          mobile={mobile}
          id={`row--row-heading-${row.type}`}
          headers={`row-section-heading-${currentFeatureCategory}`}
        />
        {/* lastly is the values for the plans */}
        {Object.entries(row.plans)
          .filter(([key]) => {
            return mobile ? key === mobileTabActivePlan : true;
          })
          .map(([key, value], index) => {
            const isPlus = isPlusPlan(key);
            const mainColumnHeader =
              columnsIncludingEmptyRows[index + 1] &&
              `table-column-heading-${columnsIncludingEmptyRows[index + 1]}`;

            return (
              <CellValue
                headers={`${mainColumnHeader} row--row-heading-${row.type} row-section-heading-${currentFeatureCategory}`}
                planName={key}
                key={`${row.type}-${row.summary}-${key}`}
                isPricingRow={row.type.startsWith('pay_')}
                value={
                  isPlus &&
                  camelize(currentSectionTitle) === 'shopifyPayments' &&
                  row.type.includes('_rate')
                    ? t('pricing:plusCompetitiveCardRatesShort')
                    : value
                }
                mobile={mobile}
              />
            );
          })}
      </tr>
    );
  });

  const signupLinks = mobile ? (
    <tr data-pricing-row-type="bottomLinks">
      <CellValue
        planName={mobileTabActivePlan}
        colSpan={3}
        isSignupLink={isSignupLink}
        value=""
        mobile={mobile}
      />
    </tr>
  ) : (
    <tr data-pricing-row-type="bottomLinks">
      {columnsIncludingEmptyRows.map((key, idx) => {
        return key ? (
          <CellValue
            isSignupLink={isSignupLink}
            planName={key}
            key={`signup-link-${key}`}
            value=""
            mobile={mobile}
          />
        ) : (
          <EmptyRowValue
            key={`table-billom-link-empty-cell-${idx + 1}`}
            mobile={mobile}
            includeBackground={false}
          />
        );
      })}
    </tr>
  );

  return (
    <table
      className="table-fixed w-full features-table"
      id="plans-features-comparison"
    >
      <caption className="sr-only">
        Pricing and feature information for all Shopify plans
      </caption>
      {/* column headings */}
      {mobile ? null : (
        <thead>
          <tr>
            {columnsIncludingEmptyRows.map((item, idx) => {
              const largerCell = idx === 0;
              return !item ? (
                <EmptyRowValue
                  key={`table-column-heading-empty-cell-${idx + 1}`}
                  mobile={mobile}
                  idx={idx}
                  includeBackground={false}
                />
              ) : (
                <th
                  id={`table-column-heading-${item}`}
                  key={`table-column-heading-${item}`}
                  scope="col"
                  className={cn(borderClasses, 'text-3xl py-5', {
                    'border-b-white': planCount <= 3,
                    [leftCellWidth]: largerCell,
                    [otherCellsWidth]: !largerCell,
                  })}
                >
                  {item}
                </th>
              );
            })}
          </tr>
        </thead>
      )}
      {/* table rows */}
      <tbody>
        {rows}
        {signupLinks}
      </tbody>
    </table>
  );
}
