import type {SVGAttributes} from 'react';

function SvgComponent(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      width="74"
      height="16"
      viewBox="0 0 74 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.4665 12.3947C55.1076 12.3947 54.5698 11.4676 54.9112 9.71382C55.256 7.94347 56.1161 7.04577 57.4682 7.04577C58.9457 7.04577 59.4938 7.91851 59.1444 9.71382C58.7882 11.5428 57.9373 12.3947 56.4665 12.3947ZM57.6195 6.46826C56.6908 6.46826 55.9288 6.78833 55.3872 7.39989L55.4921 6.86081C55.5252 6.69018 55.4078 6.54602 55.2354 6.54602C55.063 6.54602 54.8891 6.69018 54.8562 6.86081L53.2517 15.1055C53.2185 15.2761 53.336 15.4202 53.5084 15.4202C53.6808 15.4202 53.8547 15.2761 53.8879 15.1055L54.4837 12.0433C54.703 12.4348 55.2118 12.9715 56.3541 12.9715C57.2477 12.9715 57.9972 12.6998 58.5822 12.1629C59.186 11.6092 59.5979 10.7851 59.8065 9.71307C60.0152 8.64181 59.9233 7.81961 59.5342 7.26962C59.1581 6.73812 58.5136 6.46826 57.6195 6.46826Z"
        fill="white"
      />
      <mask
        id="mask0_15787_8947"
        // style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="68"
        y="6"
        width="6"
        height="7"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M68.3613 6.46826H73.7077V12.9719H68.3613V6.46826Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_15787_8947)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M71.4642 9.36582C70.6582 9.06987 69.8966 8.79054 70.0415 8.0469C70.1143 7.67319 70.4206 7.04581 71.6552 7.04581C72.349 7.04581 72.7869 7.23568 73.1664 7.7015C73.2377 7.78832 73.3186 7.78832 73.3533 7.78832C73.5112 7.78832 73.6706 7.6562 73.7011 7.49955C73.7179 7.41461 73.7015 7.36441 73.6637 7.29759C73.6622 7.29495 73.6607 7.29231 73.6588 7.28966C73.2735 6.74458 72.6374 6.46826 71.7678 6.46826C70.5003 6.46826 69.5953 7.07299 69.4054 8.04652C69.1788 9.21181 70.2211 9.60213 71.1411 9.94639C71.936 10.2438 72.6873 10.5247 72.5481 11.2374C72.3521 12.2441 71.2857 12.3944 70.667 12.3944C69.8142 12.3944 69.2878 12.1649 68.9061 11.6266C68.9049 11.6251 68.9034 11.6232 68.9022 11.6217C68.8549 11.5639 68.792 11.5348 68.7157 11.5348C68.575 11.5348 68.4015 11.6511 68.3679 11.8236C68.3511 11.9082 68.3675 11.958 68.4053 12.0252C68.6593 12.4959 69.4229 12.9719 70.5549 12.9719C71.9901 12.9719 72.973 12.3234 73.1843 11.2374C73.4101 10.0796 72.3761 9.70027 71.4642 9.36582Z"
          fill="white"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.5412 4.84052H68.8699L68.9992 4.17615C69.0324 4.00553 68.9149 3.86133 68.7425 3.86133C68.5701 3.86133 68.3962 4.00553 68.363 4.17615L68.2337 4.84052H67.5624C67.3901 4.84052 67.2165 4.98472 67.1834 5.15534C67.1502 5.32596 67.2676 5.47016 67.44 5.47016H68.1113L67.982 6.13453C67.9488 6.30515 68.0663 6.44935 68.2387 6.44935C68.4111 6.44935 68.585 6.30515 68.6178 6.13453L68.7471 5.47016H69.4184C69.5904 5.47016 69.7643 5.32596 69.7975 5.15534C69.8314 4.9851 69.7136 4.84052 69.5412 4.84052Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2459 11.2392C16.572 11.4072 17.159 11.6394 17.7201 11.6265C18.2289 11.6265 18.5027 11.3551 18.5027 11.0199C18.5027 10.6972 18.307 10.4778 17.759 10.1679C17.0808 9.7806 16.572 9.2382 16.572 8.52815C16.572 7.27563 17.6548 6.38477 19.2331 6.38477C19.9246 6.38477 20.4593 6.5139 20.7461 6.68188L20.3289 7.94721C20.081 7.83094 19.6767 7.70184 19.2072 7.70184C18.6984 7.70184 18.3723 7.93437 18.3723 8.29562C18.3723 8.57949 18.6072 8.78635 19.0638 9.03171C19.7682 9.43185 20.3552 9.97425 20.3552 10.7361C20.3552 12.1561 19.1942 12.9568 17.5767 12.9439C16.8329 12.9311 16.1414 12.7371 15.8027 12.5049L16.2459 11.2392Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7188 12.9085L22.4667 3.86816H24.267L23.5755 7.37272L23.6014 7.34969C24.0839 6.78158 24.7102 6.38937 25.5058 6.38937C26.4452 6.38937 26.9799 6.98731 26.9799 7.99443C26.9799 8.30434 26.9277 8.80526 26.8495 9.19294L26.145 12.9089H24.3448L25.0229 9.30433C25.0748 9.05897 25.1015 8.74147 25.1015 8.49648C25.1015 8.10919 24.9451 7.82796 24.5404 7.82796C23.9664 7.82796 23.3531 8.5867 23.1186 9.76184L22.5182 12.9089H20.7188V12.9085Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.2907 10.4261C29.2907 11.0716 29.5516 11.588 30.1649 11.588C31.1169 11.588 31.652 9.90935 31.652 8.81201C31.652 8.28278 31.443 7.74067 30.8041 7.74067C29.8254 7.74034 29.2907 9.40579 29.2907 10.4261ZM33.4911 8.86369C33.4911 11.0716 32.0559 12.9568 29.9295 12.9568C28.312 12.9568 27.4512 11.8462 27.4512 10.4646C27.4512 8.30841 28.8864 6.37158 31.0516 6.37158C32.7348 6.37196 33.4911 7.58557 33.4911 8.86369Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.6308 11.3552C35.8265 11.51 36.0481 11.6134 36.3746 11.6134C37.3792 11.6134 38.0707 9.97356 38.0707 8.82451C38.0707 8.34703 37.901 7.84343 37.3533 7.84343C36.727 7.84343 36.14 8.57952 35.9314 9.6897L35.6308 11.3552ZM33.0605 15.4199L34.3127 8.90457C34.4565 8.16848 34.5995 7.21643 34.6781 6.5464H36.2697L36.1652 7.55092H36.1911C36.6736 6.88122 37.3784 6.43164 38.0955 6.43164C39.4129 6.43164 39.9479 7.47312 39.9479 8.67393C39.9479 10.8433 38.5653 12.9798 36.452 12.9798C36.0084 12.9798 35.6041 12.9085 35.3951 12.7413H35.3558L34.86 15.4199H33.0605Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.3373 5.87707C41.8285 5.87707 41.4894 5.44636 41.4894 4.91713C41.4894 4.33619 41.9459 3.84961 42.52 3.84961C43.0677 3.84961 43.4201 4.27617 43.4201 4.79256C43.4071 5.45089 42.9242 5.87745 42.3636 5.87745L42.3373 5.87707ZM40.002 12.9084L41.2152 6.54673H43.0284L41.8022 12.9084H40.002Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.3672 12.9087L44.3196 7.88626H43.4847L43.7455 6.54695H44.5805L44.6327 6.22685C44.7765 5.47792 45.0629 4.71729 45.6762 4.20089C46.1587 3.78793 46.7979 3.60107 47.4376 3.60107C47.8812 3.60107 48.2072 3.66298 48.4159 3.7532L48.0639 5.14611C47.9074 5.09477 47.7248 5.05514 47.4898 5.05514C46.8895 5.05514 46.5241 5.62023 46.42 6.22722L46.3547 6.54695H47.6069L47.359 7.88626H46.1198L45.1674 12.9087H43.3672Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.8367 6.54688L50.1236 9.39461C50.2021 10.0273 50.254 10.5648 50.2799 10.8996H50.3058C50.4496 10.5648 50.5801 10.0658 50.841 9.38177L51.9368 6.54725H53.8152L51.6107 11.2046C50.828 12.8055 50.0713 13.9723 49.2494 14.7341C48.6101 15.3279 47.8534 15.6208 47.488 15.6982L46.9922 14.1852C47.2924 14.0818 47.6703 13.9259 48.0098 13.6809C48.427 13.3971 48.7795 13.009 48.9881 12.6089C49.04 12.5187 49.0533 12.4575 49.0274 12.3284L47.9446 6.54763L49.8367 6.54688Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.0696 7.14507C67.8972 7.14507 67.7233 7.28927 67.6901 7.4599L67.0821 10.5851C66.7522 12.1769 65.7644 12.3944 64.8383 12.3944C63.1704 12.3944 63.1285 11.454 63.3074 10.5341L64.0221 6.86117C64.0553 6.69059 63.9378 6.54639 63.7654 6.54639C63.593 6.54639 63.4191 6.69059 63.3859 6.86117L62.6711 10.5341C62.5274 11.2744 62.5785 11.827 62.8287 12.223C63.1422 12.7197 63.7807 12.9715 64.7258 12.9715C65.6709 12.9715 66.407 12.7194 66.9143 12.223C67.3186 11.827 67.5852 11.2744 67.729 10.5341L68.327 7.4599C68.3595 7.28927 68.242 7.14507 68.0696 7.14507Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.3214 3.87891C62.149 3.87891 61.9751 4.0231 61.9419 4.19373L60.3103 12.5795C60.2771 12.7501 60.3945 12.8943 60.567 12.8943C60.7394 12.8943 60.9133 12.7501 60.9465 12.5795L62.5781 4.19373C62.6113 4.02273 62.4939 3.87891 62.3214 3.87891Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.04702 3.04161C8.04168 2.68489 7.99858 2.18888 7.83076 1.76006C8.37083 1.86122 8.63667 2.46595 8.74881 2.82645C8.54094 2.89024 8.30409 2.96272 8.04702 3.04161ZM5.22004 7.77299C5.2681 8.52682 7.272 8.6914 7.38452 10.4573C7.473 11.8464 6.64001 12.7965 5.43973 12.8716C3.99916 12.9615 3.20621 12.1205 3.20621 12.1205L3.51134 10.8351C3.51134 10.8351 4.30962 11.4312 4.94848 11.3911C5.36574 11.3651 5.51486 11.0292 5.49999 10.7913C5.43744 9.80796 3.80578 9.86572 3.70242 8.25009C3.61584 6.89039 4.51787 5.51258 6.50881 5.38805C7.27582 5.34011 7.66867 5.53376 7.66867 5.53376L7.21327 7.21922C7.21327 7.21922 6.70562 6.99043 6.10337 7.02784C5.22042 7.08408 5.21088 7.63517 5.22004 7.77299ZM6.60454 1.32784C6.7716 1.32444 6.91234 1.36068 7.03248 1.43882C6.84025 1.53772 6.65413 1.67966 6.47982 1.86462C6.02786 2.3444 5.68154 3.08955 5.54347 3.80828C5.11248 3.9404 4.69103 4.06946 4.30352 4.18841C4.548 3.05709 5.50609 1.35917 6.60454 1.32784ZM7.57408 3.18657C7.10037 3.33186 6.58318 3.49044 6.06409 3.64936C6.21017 3.09635 6.48669 2.5456 6.82652 2.18473C6.95277 2.05034 7.12974 1.90086 7.33913 1.81517C7.53632 2.22134 7.57865 2.79738 7.57408 3.18657ZM9.6413 2.55279C9.64016 2.55317 9.4773 2.603 9.20192 2.68755C9.15615 2.53992 9.08826 2.35835 8.99177 2.17603C8.68054 1.58832 8.22438 1.2769 7.67363 1.27611H7.67172C7.63358 1.27611 7.59544 1.2795 7.5573 1.28294C7.54128 1.26365 7.52488 1.24444 7.50733 1.22632C7.26743 0.972271 6.95925 0.848419 6.59082 0.859026C5.87911 0.87941 5.17046 1.38784 4.59606 2.2912C4.19139 2.92684 3.88436 3.72563 3.79702 4.34357C2.98004 4.59384 2.40832 4.76937 2.39611 4.77315C1.98381 4.90112 1.97084 4.91395 1.91668 5.2986C1.87587 5.58964 0.796875 13.8486 0.796875 13.8486L9.75 15.3812V2.53464C9.70576 2.53766 9.66609 2.54558 9.6413 2.55279Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0469 15.3465L13.761 14.4326C13.761 14.4326 12.1618 3.73176 12.1519 3.65815C12.1416 3.58492 12.0767 3.54415 12.0233 3.53962C11.9699 3.53509 10.9241 3.51961 10.9241 3.51961C10.9241 3.51961 10.2864 2.90696 10.0469 2.6748V15.3465Z"
        fill="white"
      />
    </svg>
  );
}

export default SvgComponent;
