import classNames from 'classnames';

import {autoScrollFeatureTableParam} from '@/pages/shopify.com/($locale)/pricing/components/Comparison/hooks/useScrollToTableRow';
import Link from '@/components/base/elements/Link/Link';
import {localizePathBySite} from '@/utils/site';
import {useTranslations} from '@/hooks/useTranslations';

import {useSiteData} from '../../Page/SiteData';
import ShopifyPlusLogo from '../assets/ShopifyPlusLogo';

import {getCellClasses} from './utils/getCellClasses';

interface Props {
  id?: string;
  mobile: boolean;
  row: {
    title: string;
    featureCategory: string;
  };
  colSpan: number;
}

export const FeatureCategory = ({id, mobile, row, colSpan}: Props) => {
  const {cellClasses, leftCellWidth, autoScrollHighlightClass} = getCellClasses(
    {mobile},
  );
  const {site} = useSiteData();
  const {t} = useTranslations();

  /**
   * Info derived from json,
   * @see "public/locales/shopify.com/en/plans.json"
   */
  const linkMap: Record<string, string> = {
    // eslint-disable-next-line camelcase
    international_commerce: localizePathBySite('/markets', site),
    // eslint-disable-next-line camelcase
    shopify_tax: localizePathBySite('/tax', site),
    pos: localizePathBySite('/pos/features', site),
  };
  const url = linkMap[row.featureCategory];
  const isPlusFeatures = row.featureCategory.includes('plus_exclusive');

  const getRowContent = () => {
    if (isPlusFeatures) {
      return (
        <div className="flex flex-row gap-2">
          <span>{row?.title}</span>
          <span
            className={classNames(
              'bg-commerce-store-front rounded-full px-2 text-xs text-white font-semibold uppercase flex flex-row gap-1 items-center',
            )}
          >
            <ShopifyPlusLogo />
            {t('pricing:plusExclusiveFeatures')}
          </span>
        </div>
      );
    }

    if (url) {
      return (
        <Link
          className="py-0 text-xl"
          mode="light"
          href={url}
          componentName={row.featureCategory}
        >
          {row.title}
        </Link>
      );
    }

    return row?.title;
  };

  return (
    <th
      id={id}
      colSpan={colSpan}
      className={classNames(
        'text-left bg-[#EBF2F6] text-xl',
        leftCellWidth,
        cellClasses,
        {
          [autoScrollHighlightClass]:
            row?.featureCategory === autoScrollFeatureTableParam(),
        },
        'py-4',
      )}
    >
      {getRowContent()}
    </th>
  );
};
