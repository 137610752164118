import type {SVGAttributes} from 'react';
import classNames from 'classnames';

function CheckIcon(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      {...props}
      viewBox="0 0 40.7 40"
      className={classNames(
        'fill-jalapeño-50 m-auto block h-6 w-6',
        props.className,
      )}
    >
      <path d="M16.7 30l-7.4-7.9c-.8-.8-.6-2 .4-2.6 1-.6 2.4-.5 3.1.3l3.7 4 11.3-13c.7-.8 2.1-1 3.1-.4s1.2 1.8.5 2.6L16.7 30z" />
    </svg>
  );
}

export default CheckIcon;
