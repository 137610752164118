import type {PlanComponent} from '@/types';
import type {normalizeServerPlanSummaryData} from '@/components/shared/Plans/FeaturesTable/utils/normalizeServerPlanSummaryData';
import type {Plan} from '@/components/shared/Plans/FeaturesTable/utils/normalizePlanTypes';
import useIntlLocale from '@/hooks/useIntlLocale';
import {formatCurrency} from '@/utils/string/formatCurrency';
import {PlanName} from '@/enums';

export const isPlusPlan = (planName?: string) => {
  return (
    planName?.toLocaleLowerCase() === 'shopify_plus' ||
    planName?.toLocaleLowerCase() === 'plus' ||
    false
  );
};

export const isPlusPath = (path: string) => {
  return path.includes('plus') || false;
};

export const usePlansInterpolations = (
  plans: PlanComponent[],
  serverPlansData: ReturnType<typeof normalizeServerPlanSummaryData>,
) => {
  const serverPlansArray = serverPlansData.mainPlans.concat(
    serverPlansData.alternatePlans,
  );

  // Magic (localized) numbers!
  const locale = useIntlLocale(true);

  const interpolate = (serverPlanData: Plan) => {
    const getPlanPrice = () => {
      let monthlyPrice = serverPlanData.monthly_price.amount;

      return (
        formatCurrency(
          locale,
          serverPlanData.monthly_price.currency_code,
          monthlyPrice,
          {
            maximumFractionDigits: 0,
          },
        ) || ''
      );
    };

    return (text: string | undefined) => {
      if (!text) {
        return '';
      }

      return text
        .replace('{plan_display_name}', serverPlanData.display_name)
        .replace('{plan_monthly_price}', getPlanPrice())
        .replace('{plan_currency}', serverPlanData.monthly_price.currency_code)
        .replace('{starter_plan_price}', getPlanPrice())
        .replace('{starter_display_name}', 'Starter')
        .replace(
          '{starter_plan_annual_price}',
          String(serverPlanData.annual_price.amount),
        )
        .replace(
          '{starter_plan_price_currency}',
          serverPlanData.monthly_price.currency_code,
        )
        .replace('{starter_plan_price_currency_symbol}', '');
    };
  };

  return [...plans]
    .map((plan) => {
      let serverPlanData: Plan | undefined;

      switch (plan.name) {
        case PlanName.Starter:
          serverPlanData = serverPlansArray.find(
            (pData) => pData.internal_name === 'starter_2022',
          );
          break;
        case PlanName.Plus:
        case PlanName.CommerceComponents:
          serverPlanData = serverPlansArray[0];
          break;
        default:
          serverPlanData = serverPlansArray.find(
            (pData) => pData.internal_name === plan.name,
          );
      }

      if (!serverPlanData) return null as never;

      const {
        description,
        moreFeaturesHeading,
        moreFeaturesHeadingIncentive,
        addOn,
        addOnMonthlyIncentive,
        ...rest
      } = plan as PlanComponent;

      const name: string = plan.name || serverPlanData.internal_name;
      const displayName: string =
        plan.displayName || serverPlanData.display_name;

      const replace = interpolate(serverPlanData);

      return {
        ...rest,
        name,
        currencyCode: serverPlanData.monthly_price.currency_code,
        displayName: replace(displayName),
        description: replace(description),
        moreFeaturesHeading: replace(moreFeaturesHeading),
        moreFeaturesHeadingIncentive: replace(moreFeaturesHeadingIncentive),
        addOn: replace(addOn),
        addOnMonthlyIncentive: replace(addOnMonthlyIncentive),
      } as PlanComponent;
    })

    .filter((plan) => plan); // Filter out lookup misses for plans that don't exist in in plans.json;
};
