import cn from 'classnames';
import {useLocation} from '@remix-run/react';

import Button from '@/components/base/elements/Button/Button';
import {ButtonSizeEnum} from '@/enums';
import {
  CheckIcon,
  XIcon,
} from '@/pages/shopify.com/($locale)/pricing/components/Comparison/assets';
import {useTranslations} from '@/hooks/useTranslations';
import useSignup from '@/hooks/useSignup';
import {useSignupUrl} from '@/hooks/useSignupUrl';
import {START_FREE_TRIAL_NAME, PLUS_GET_IN_TOUCH} from '@/constants';

import {isPlusPlan, isPlusPath} from '../utils';

import {getCellClasses} from './utils/getCellClasses';

interface PlanRowValueProps {
  value: string | boolean;
  bold: boolean;
  mobile: boolean;
}

const PlanRowValue = ({value, bold, mobile}: PlanRowValueProps) => {
  const location = useLocation();
  const isPlus = isPlusPath(location.pathname);
  return (
    <div>
      {typeof value === 'boolean' ? (
        value === true ? (
          <CheckIcon
            className={cn(mobile && 'ml-auto mr-0', {
              'fill-[#255dee]': isPlus,
            })}
          />
        ) : (
          <XIcon className={cn(mobile && 'ml-auto mr-0')} />
        )
      ) : (
        <span
          className={cn(
            'inline-flex gap-1 flex-wrap max-md:justify-end justify-center',
            bold ? 'text-lg font-bold' : 'font-normal text-shade-50 text-sm',
          )}
          dangerouslySetInnerHTML={{
            __html: value,
          }}
        />
      )}
    </div>
  );
};

interface Props extends Omit<PlanRowValueProps, 'bold'> {
  mobile: boolean;
  headers?: string;
  isSignupLink?: boolean;
  colSpan?: number;
  planName: string;
  isPricingRow?: boolean;
}

export const CellValue = ({
  value,
  mobile,
  headers,
  isSignupLink,
  colSpan,
  planName,
  isPricingRow = false,
}: Props) => {
  const {t} = useTranslations();
  const {cellClasses, otherCellsWidth} = getCellClasses({
    mobile,
  });
  const isPlus = isPlusPlan(planName);
  const {signupUrl} = useSignup();
  const {getSignupURL} = useSignupUrl();
  const {localizePath} = useTranslations();

  const formatCellValue = (cellValue: string | boolean) => {
    /**
     * For Plus we want to prepend "Starting at" to the price
     * returned from core and append the 3 year term details
     * with special styling. We format as a string as the PlanRowValue
     * component uses dangerouslySetInnerHTML to render the value.
     */
    if (isPlus && isPricingRow && typeof cellValue === 'string') {
      const subduedStyling = 'text-sm text-shade-60 font-light';
      const content = [
        `<span class="${subduedStyling}">${t('pricing:startingAt')}</span>`,
        `<span class="block">${cellValue}</span>`,
        `<span class="${subduedStyling}">${t(
          'pricing:plusPricingTerm',
        )}</span>`,
      ];
      return `<div class="leading-5">${content.join('')}<div>`;
    }
    return cellValue;
  };

  return (
    <td
      headers={headers}
      data-section-name={`comparison-table-${planName}`}
      colSpan={colSpan}
      className={cn(
        otherCellsWidth,
        cellClasses,
        mobile && !colSpan ? 'text-right' : 'text-center',
        {'px-4': mobile},
      )}
    >
      {isSignupLink ? (
        <Button
          mode="light"
          intent="secondary"
          size={ButtonSizeEnum.Small}
          href={
            isPlus
              ? localizePath(
                  '/plus?itcat=pricing_plan&itterm=get_in_touch#contact-sales',
                )
              : getSignupURL({url: signupUrl}).href
          }
          componentName={isPlus ? PLUS_GET_IN_TOUCH : START_FREE_TRIAL_NAME}
        >
          {isPlus
            ? t('global:signup.plusButtonText')
            : t('global:signup.buttonText')}
        </Button>
      ) : (
        <PlanRowValue
          mobile={mobile}
          bold={isPricingRow}
          value={formatCellValue(value)}
        />
      )}
    </td>
  );
};
