function ChevronDown({className}: {className: string}) {
  return (
    <svg
      width={14}
      height={8}
      viewBox="0 0 14 8"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.246 7.48c-.275 0-.55-.097-.76-.291L1.108 2.213a.942.942 0 010-1.407c.42-.389 1.1-.389 1.521 0l4.617 4.272L11.863.806c.42-.389 1.1-.389 1.52 0a.942.942 0 010 1.407L8.006 7.19c-.21.194-.485.291-.76.291"
        fill="#5C5F62"
      />
    </svg>
  );
}

export default ChevronDown;
