import type {SVGAttributes} from 'react';
import classNames from 'classnames';

function XIcon(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      width={13}
      height={12}
      viewBox="0 0 13 12"
      fill="none"
      {...props}
      className={classNames(
        'm-auto block h-3 w-3 fill-[#979797]',
        props.className,
      )}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.315.351a1.2 1.2 0 010 1.698L8.364 6l3.951 3.951a1.2 1.2 0 01-1.697 1.697l-3.951-3.95-3.952 3.95a1.2 1.2 0 11-1.697-1.697L4.97 6 1.018 2.049A1.2 1.2 0 012.715.35l3.952 3.952L10.618.35a1.2 1.2 0 011.697 0z"
      />
    </svg>
  );
}

export default XIcon;
