import classNames from 'classnames';

import {getCellClasses} from './utils/getCellClasses';

interface Props {
  id?: string;
  headers?: string;
  row: {
    summary: string;
    description?: string;
    type: string;
  };
  mobile: boolean;
}

export const FeatureDetails = ({id, headers, row, mobile}: Props) => {
  const {cellClasses, leftCellWidth} = getCellClasses({mobile});

  return (
    <th
      id={id}
      headers={headers}
      className={classNames(leftCellWidth, cellClasses)}
      colSpan={mobile ? 2 : 1}
    >
      <dl>
        <dt className="text-lg font-normal text-left pb-1">{row?.summary}</dt>
        <dd className="text-sm font-normal text-[#6b7177] text-left">
          {row?.description || ''}
        </dd>
      </dl>
    </th>
  );
};
