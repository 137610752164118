interface Props {
  mobile: boolean;
}

export const getCellClasses = ({mobile}: Props) => {
  const borderClasses = 'border-solid border border-transparent';
  const cellClasses = 'p-2';
  const minimalBorderClasses = mobile ? '' : borderClasses;
  const leftCellWidth = mobile ? 'w-[80%]' : 'w-[33.33%]';
  const otherCellsWidth = 'w-auto';
  const autoScrollHighlightClass = 'bg-banana-10';
  const mobileCellBorderTop = mobile
    ? 'border-solid border-t border-t-shade-30'
    : '';
  const mobileCellBorderBottom = mobile
    ? 'border-solid border-b border-b-shade-30'
    : '';

  return {
    borderClasses,
    cellClasses,
    leftCellWidth,
    minimalBorderClasses,
    otherCellsWidth,
    autoScrollHighlightClass,
    mobileCellBorderTop,
    mobileCellBorderBottom,
  };
};
