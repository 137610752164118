import cn from 'classnames';
import {useEffect, useRef, useState} from 'react';

import {useMediaQuery} from '@/hooks/useMediaQuery';
import {useTableResize} from '@/hooks/useTableResize';
import FeaturesTableV2 from '@/components/shared/Plans/FeaturesTable/FeaturesTableV2';
import {useScrollToTableRow} from '@/pages/shopify.com/($locale)/pricing/components/Comparison/hooks/useScrollToTableRow';

import {ToggleBar} from './ToggleBar';
import {type normalizeServerTableData} from './utils/normalizeServerTableData';

export interface PlanFeaturesTableMobileProps {
  serverTableData?: ReturnType<typeof normalizeServerTableData>;
  mobileTabActivePlan: string;
  setShowTableParent?: (value: boolean) => void;
  showTableParent?: boolean;
  isSignupLink?: boolean;
}

export default function PlanFeaturesTableMobile({
  mobileTabActivePlan = 'basic',
  serverTableData,
  setShowTableParent,
  showTableParent,
  isSignupLink = true,
}: PlanFeaturesTableMobileProps) {
  const accordionRef = useRef<HTMLDivElement>(null);
  const sectionRef = useRef<HTMLButtonElement>(null);
  const [showTable, setShowTable] = useState(false);
  const [tableSectionHeight, setTableSectionHeight] = useState(0);
  const [lastButtonClicked, setLastButtonClicked] = useState(-1);
  const isMobileView = useMediaQuery('(max-width: 900px)');
  const prefersReducedMotion = useMediaQuery(
    '(prefers-reduced-motion: reduce)',
  );

  const actualShowTable = showTableParent ? showTableParent : showTable;
  const actualSetShowTable = setShowTableParent
    ? setShowTableParent
    : setShowTable;

  useScrollToTableRow(actualSetShowTable);

  useTableResize({ref: accordionRef, setHeight: setTableSectionHeight});
  const handleClick = (buttonId: number) => () => {
    setLastButtonClicked(buttonId);
    actualSetShowTable(!actualShowTable);
  };

  useEffect(() => {
    if (!actualShowTable && sectionRef.current && lastButtonClicked === 1) {
      sectionRef.current.scrollIntoView({block: 'center'});
    }
  }, [actualShowTable, sectionRef, lastButtonClicked]);

  // if resize to desktop and come back table is not shown,
  // but the toggle is still in open state,
  // so close it when resize to desktop
  useEffect(() => {
    if (!isMobileView) {
      actualSetShowTable(false);
    }
  }, [isMobileView, actualSetShowTable]);

  const accessibilityIds = {
    topToggle: 'top-toggle-bar-for-mobile-plan-features-table',
    bottomToggle: 'bottom-toggle-bar-for-mobile-plan-features-table',
    table: 'mobile-plan-features-table',
  };

  useEffect(
    function applyInertToPricingTable() {
      const tableEl = accordionRef.current;
      tableEl && (tableEl.inert = !actualShowTable);
    },
    [accessibilityIds.table, actualShowTable],
  );

  return (
    <section
      id="plan-features-table-mobile"
      className="relative block md:hidden"
    >
      <ToggleBar
        ref={sectionRef}
        handleClick={handleClick(0)}
        showTable={actualShowTable}
        id={accessibilityIds.topToggle}
        associatedElementId={accessibilityIds.table}
      />

      <div
        id={accessibilityIds.table}
        aria-labelledby={Object.values(accessibilityIds).join(' ')}
        ref={accordionRef}
        className={cn('md:hidden overflow-hidden', {
          'duration-500 transition-all': !prefersReducedMotion,
          'max-h-0 [&>*]:invisible': !actualShowTable,
          block: isMobileView,
          'pointer-events-none': !actualShowTable,
        })}
        style={{
          maxHeight: actualShowTable ? `${tableSectionHeight ?? 0}px` : '0',
        }}
        role="region"
        aria-hidden={!actualShowTable}
      >
        {isMobileView && (
          <>
            {serverTableData && (
              <FeaturesTableV2
                table={serverTableData}
                mobile
                mobileTabActivePlan={mobileTabActivePlan}
                isSignupLink={isSignupLink}
              />
            )}
            <ToggleBar
              id={accessibilityIds.bottomToggle}
              associatedElementId={accessibilityIds.table}
              handleClick={handleClick(1)}
              showTable={actualShowTable}
            />
          </>
        )}
      </div>
    </section>
  );
}
