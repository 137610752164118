import {Key} from '@/enums';

interface GetTabIndexArgs {
  index: number;
  event: React.KeyboardEvent<HTMLButtonElement>;
  tabCount: number;
}

export function getTabIndex({index, event, tabCount}: GetTabIndexArgs) {
  const {key} = event;
  const arrowKeys = [Key.ArrowUp, Key.ArrowDown, Key.ArrowLeft, Key.ArrowRight];

  if (arrowKeys.includes(key as Key)) {
    event.preventDefault();
    let newIndex;

    if (key === Key.ArrowRight || key === Key.ArrowDown) {
      newIndex = (index + 1) % tabCount;
    }

    if (key === Key.ArrowLeft || key === Key.ArrowUp) {
      newIndex = (index - 1 + tabCount) % tabCount;
    }

    return newIndex;
  }
}
