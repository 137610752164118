import {FeatureCategory} from './FeatureCategory';
import {getCellClasses} from './utils/getCellClasses';

interface Props {
  id?: string;
  row: {
    featureCategory: string;
    title: string;
  };
  colspan: number;
  mobile: boolean;
}

export const RowSectionDivider = ({id, row, colspan, mobile}: Props) => {
  const {cellClasses} = getCellClasses({mobile});
  const featureHeaderFillSize = mobile ? 3 : colspan;

  return (
    <>
      <tr
        className={cellClasses}
        data-pricing-row-section-divider
        data-pricing-row-type={row?.featureCategory}
      >
        <FeatureCategory
          id={id}
          colSpan={featureHeaderFillSize}
          mobile={mobile}
          key={row?.featureCategory}
          row={row}
        />
      </tr>
    </>
  );
};
