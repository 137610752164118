import classNames from 'classnames';

import {autoScrollFeatureTableParam} from '@/pages/shopify.com/($locale)/pricing/components/Comparison/hooks/useScrollToTableRow';

import {getCellClasses} from './utils/getCellClasses';

interface Props {
  featureCategory?: string;
  mobile: boolean;
  idx?: number;
  includeBackground?: boolean;
}

export const EmptyRowValue = ({
  idx = 0,
  featureCategory = '',
  mobile = false,
  includeBackground = true,
}: Props) => {
  const {autoScrollHighlightClass, otherCellsWidth, cellClasses} =
    getCellClasses({mobile});

  return (
    <td
      colSpan={1}
      key={`empty-row-${idx}`}
      className={classNames(cellClasses, otherCellsWidth, {
        [autoScrollHighlightClass]:
          featureCategory === autoScrollFeatureTableParam(),
        'bg-[#EBF2F6]': includeBackground,
      })}
    ></td>
  );
};
