import {useEffect} from 'react';

export type StateSetter<T> = React.Dispatch<React.SetStateAction<T>>;

const QUERY_PARAM = 'feature';
export const ROW_DOM_ATTRIBUTE = 'data-pricing-row-type';

export const autoScrollFeatureTableParam = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(QUERY_PARAM);
};

export const rowSelector = (rowHandle: string) => {
  return `[${ROW_DOM_ATTRIBUTE}="${rowHandle}"]`;
};

const getValidTableRows = () => {
  const tableRows: NodeListOf<HTMLElement> = document.querySelectorAll(
    `[${ROW_DOM_ATTRIBUTE}]`,
  );
  const validRowValues: string[] = [];
  tableRows.forEach((row) => {
    const rowValue = row.getAttribute(ROW_DOM_ATTRIBUTE);
    if (rowValue) {
      validRowValues.push(rowValue);
    }
  });
  return validRowValues;
};

const isValidRow = (rowHandle: string) => {
  const validRowValues = getValidTableRows();
  return validRowValues.includes(rowHandle);
};

// check for query param `?feature=` to show table and scroll to a specific row
export const useScrollToTableRow = (setShowTable: (value: boolean) => void) => {
  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    let timerRowScroll: ReturnType<typeof setTimeout>;
    const showTableFeatureRowParam: ReturnType<
      typeof autoScrollFeatureTableParam
    > = autoScrollFeatureTableParam();

    if (showTableFeatureRowParam) {
      timer = setTimeout(() => {
        // Don't proceed to open table and scroll to row if the row is not valid
        if (!isValidRow(showTableFeatureRowParam)) {
          return;
        }

        setShowTable(true);

        const row = document.querySelector(
          rowSelector(showTableFeatureRowParam),
        );
        if (row) {
          timerRowScroll = setTimeout(() => {
            row.scrollIntoView({block: 'center'});
          }, 500);
        }
      }, 500);
    }

    return () => {
      clearTimeout(timer);
      clearTimeout(timerRowScroll);
    };
  }, [setShowTable]);
};
