import cn from 'classnames';
import type {ForwardedRef} from 'react';
import {forwardRef} from 'react';

import {useTranslations} from '@/hooks/useTranslations';

import ChevronDown from './ToggleIcon';

interface Props {
  handleClick: () => void;
  showTable: boolean;
  associatedElementId: string;
  id: string;
}

export const ToggleBar = forwardRef(
  (
    {handleClick, showTable, id, associatedElementId}: Props,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const {t} = useTranslations();

    const buttonText = showTable
      ? t('pricing:toggleHideText')
      : t('pricing:toggleShowText');

    return (
      <button
        ref={ref}
        type="button"
        onClick={handleClick}
        id={id}
        aria-controls={associatedElementId}
        aria-label={buttonText}
        aria-expanded={showTable}
        className="border-b-shade-30 border-t-shade-30 flex w-full select-none justify-between border-y border-solid px-4 py-5 text-left text-lg font-bold bg-white"
      >
        <span>{buttonText}</span>
        <span className="flex h-7 w-7 items-center justify-center">
          <ChevronDown
            className={cn('mr-auto', {
              'rotate-180': showTable,
            })}
          />
        </span>
      </button>
    );
  },
);
ToggleBar.displayName = 'ToggleBar';
