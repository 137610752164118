import {useRef, useEffect} from 'react';
import cn from 'classnames';

import {useTranslations} from '@/hooks/useTranslations';
import {twMerge} from '@/stylesheets/twMerge';
import {PricingSliderEnum} from '@/enums';
import type {PlanComponent} from '@/types';
import {useComponentPosition} from '@/hooks/useComponentPosition';
import useReducedMotion from '@/hooks/useReducedMotion';

import {isPlusPlan} from './utils';
import {getTabIndex} from './utilities/tabIndex';

interface Props {
  sliderValue?: PricingSliderEnum;
  plans?: PlanComponent[];
  tabHighlightColor?: string;
  mobileTabActivePlan: string;
  onMobileTabActivePlanChange: (planName: string) => void;
  className?: string;
}

export default function MobilePricingPlansTabs({
  plans = [],
  sliderValue,
  tabHighlightColor = 'bg-aloe-30',
  mobileTabActivePlan,
  onMobileTabActivePlanChange,
  className,
}: Props) {
  const {t} = useTranslations();
  const prefersReducedMotion = useReducedMotion(false);
  const {bottom: scrollingTabNavTopStart} =
    useComponentPosition('main > section');

  const {bottom: scrollingTabNavTopEnd, scrollPosition} = useComponentPosition(
    '#plan-features-table-mobile',
  );

  const buttonRefs = useRef<(HTMLButtonElement | null)[]>([]);

  useEffect(() => {
    buttonRefs.current = buttonRefs.current.slice(0, plans?.length);
  }, [plans]);

  if (plans?.length === 0 || plans?.length === 1) return null;

  // add 84 since on the pricing page, we pull up the section a bit to deal
  // with spacing issues
  const navSticky =
    scrollingTabNavTopStart < scrollPosition &&
    scrollPosition < scrollingTabNavTopEnd;

  const handlePlanChange = (planName?: string) => {
    if (planName) {
      onMobileTabActivePlanChange(planName);
    }
  };

  function handleKeyDown(
    index: number,
    event: React.KeyboardEvent<HTMLButtonElement>,
  ) {
    const tabCount = plans.length;
    const newTabIndex = getTabIndex({index, event, tabCount});

    if (newTabIndex !== undefined) {
      handlePlanChange(plans[newTabIndex].name);
      buttonRefs.current[newTabIndex]?.focus();
    }
  }

  return (
    <div
      aria-label={t('pricing:plansScreenReaderHeader')}
      role="tablist"
      className={twMerge(
        cn(
          'plans-mobile-tab-container flex md:hidden relative',
          {
            'sticky left-0 w-full z-20 bg-white border-b border-solid border-b-shade-30':
              navSticky,
            'top-[72px]': navSticky && !prefersReducedMotion,
            'top-0': navSticky && prefersReducedMotion,
          },
          className,
        ),
      )}
    >
      {plans.map((plan, index) => {
        const selected = plan.name === mobileTabActivePlan;
        const tabIndex = selected ? undefined : -1;

        return (
          <button
            ref={(el) => (buttonRefs.current[index] = el)}
            data-component-extra-plan-amount={
              plan.price?.[
                sliderValue === PricingSliderEnum.Yearly
                  ? 'annualPrice'
                  : 'monthlyPrice'
              ]
            }
            tabIndex={tabIndex}
            data-component-extra-plan-currency={plan?.currencyCode}
            id={`tab-button-for-${plan.name}`}
            role="tab"
            key={`MobilePricingPlansTabs-${plan.name}-${index}`}
            onClick={() => handlePlanChange(plan.name)}
            onKeyDown={(event) => handleKeyDown(index, event)}
            aria-selected={selected}
            aria-controls={`tab-panel-for-${plan.name}`}
            className={cn(
              'plan-mobile-tab relative border-t border-r flex-1 text-center cursor-pointer h-14 flex items-center justify-center mt-auto font-bold text-sm tracking-tight',
              {
                'bg-white/50 border-shade-30': !selected,
                'box-content bg-shade-10 text-black py-0 border-t-0': selected,
                'bg-white': navSticky,
                'border-r-0': index === plans.length - 1,
              },
            )}
          >
            {selected && (
              <span
                className={twMerge(
                  cn('absolute top-0 w-full h-1', tabHighlightColor, {
                    'bg-commerce-store-front': isPlusPlan(plan.name),
                  }),
                )}
              />
            )}
            {plan.displayName}
          </button>
        );
      })}
    </div>
  );
}
